<template>
  <div
    class="list__content-adding list__content-adding-company ym-hide-content"
  >
    <div class="title card">
      <div
        v-if="'CreateDealer' == this.$route.name"
        class="title__avatar title__avatar-dealerPage"
      ></div>
      <div
        v-else-if="
          'CreateCompany' == this.$route.name ||
            'CompanyEdit' == this.$route.name ||
            'CompanyEditId' == this.$route.name ||
            ('FromDealerCreateCompany' == this.$route.name &&
              'FromObjectCreateAccountPage' != this.$route.name &&
              'FromCompanyFromObjectCreateAccountPage' != this.$route.name &&
              'FromDealerFromObjectCreateAccountPage' != this.$route.name)
        "
        class="title__avatar title__avatar-company"
      ></div>
      <div
        v-else-if="
          'CreateObject' == this.$route.name ||
            'ObjectsEdit' == this.$route.name ||
            'ObjectsEditId' == this.$route.name ||
            'FromCompanyCreateObject' == this.$route.name ||
            'FromDealerCreateObject' == this.$route.name ||
            'FromDealerFromCompanyCreateObject' == this.$route.name
        "
        class="title__avatar title__avatar-objectsPage"
      ></div>
      <div
        v-else-if="
          'CreateKindergartens' == this.$route.name ||
            'KindergartenEditId' == this.$route.name ||
            'FromDealercreateKindergartens' == this.$route.name
        "
        class="title__avatar title__avatar-kindergardensPage"
      ></div>
      <div
        v-else-if="
          'FromKindergartenChildrenGroupsCreate' == this.$route.name ||
            'ChildrenGroupsCreate' == this.$route.name ||
            'ChildrenGroupsEdit' == this.$route.name ||
            'ChildrenGroupsEditId' == this.$route.name ||
            'FromDealerFromKindergartenChildrenGroupsCreate' == this.$route.name
        "
        class="title__avatar title__avatar-childrenGroupsPage"
      ></div>
      <div
        v-else-if="
          'FromKindergartenCreateChildren' == this.$route.name ||
            'FromKindergartenFromChildrenGroupCreateChildren' ==
              this.$route.name ||
            'ChildrenEdit' == this.$route.name ||
            'ChildrenEditId' == this.$route.name ||
            'CreateChildren' == this.$route.name ||
            'FromChildrenGroupCreateChildren' == this.$route.name ||
            'FromDealerFromKindergartenCreateChildren' == this.$route.name
        "
        class="title__avatar title__avatar-childrenPage"
      ></div>
      <div
        v-else-if="
          'FromKindergartenPersonnelCreate' == this.$route.name ||
            'FromObjectUsersCreate' == this.$route.name ||
            'CreateStaff' == this.$route.name ||
            'PersonnelCreate' == this.$route.name ||
            'FromKindergartenFromChildrenGroupManageressCreate' ==
              this.$route.name ||
            'FromChildrenGroupManageressCreate' == this.$route.name ||
            'FromServesOrganizationCreateStaff' == this.$route.name ||
            'PersonnelEditId' == this.$route.name ||
            'FromDealerFromKindergartenPersonnelCreate' == this.$route.name
        "
        class="title__avatar title__avatar-staffPage"
      ></div>
      <div
        v-else-if="
          'CreateController' == this.$route.name ||
            'ControllersEdit' == this.$route.name ||
            'ControllersEditId' == this.$route.name ||
            'FromKindergartenCreateController' == this.$route.name ||
            'FromDealerCreateController' == this.$route.name ||
            'FromObjectCreateController' == this.$route.name ||
            'FromCompanyCreateController' == this.$route.name ||
            'FromDealerFromCompanyCreateController' == this.$route.name ||
            'FromDealerFromCompanyFromObjectCreateController' ==
              this.$route.name ||
            'FromDealerFromKindergartenCreateController' == this.$route.name ||
            'FromDealerFromObjectCreateController' == this.$route.name ||
            'FromCompanyFromObjectCreateController' == this.$route.name ||
            'FromServesOrganizationFromObjectCreateController' ==
              this.$route.name
        "
        class="title__avatar title__avatar-controllersPage"
      ></div>
      <div
        v-else-if="
          'createFirmware' == this.$route.name ||
            'FirmwareEdit' == this.$route.name ||
            'FirmwareEditId' == this.$route.name
        "
        class="title__avatar title__avatar-FirmwarePage"
      ></div>
      <div
        v-else-if="
          'CreateCardPage' == this.$route.name ||
            'FromKindergartenCreateCardPage' == this.$route.name ||
            'FromDealerFromKindergartenCreateCardPage' == this.$route.name
        "
        class="title__avatar title__avatar-cardPage"
      ></div>
      <div
        v-else-if="
          'CreateAccountPage' == this.$route.name ||
            'FromObjectCreateAccountPage' == this.$route.name ||
            'FromDealerFromCompanyFromObjectCreateAccountPage' ==
              this.$route.name ||
            'FromCompanyFromObjectCreateAccountPage' == this.$route.name ||
            'FromDealerFromObjectCreateAccountPage' == this.$route.name ||
            'FromServesOrganizationFromObjectCreateAccountPage' ==
              this.$route.name
        "
        class="title__avatar title__avatar-accountPage"
      ></div>
      <div
        v-else-if="
          'UsersCreate' == this.$route.name ||
            'UsersEdit' == this.$route.name ||
            'FromObjectFromUserGroupUsersCreateObjects' == this.$route.name ||
            'usersObjectsEdit' == this.$route.name ||
            'UsersEditId' == this.$route.name ||
            'usersGeneralEdit' == this.$route.name ||
            'FromCompanyUsersCreate' == this.$route.name ||
            'FromKindergartenUsersCreate' == this.$route.name ||
            'FromKindergartenFromChildrenParentsCreate' == this.$route.name ||
            'FromKindergartenFromChildrenGroupFromChildrenEditParent' ==
              this.$route.name ||
            'FromKindergartenFromChildrenGroupFromChildrenParentsCreate' ==
              this.$route.name ||
            'FromChildrenParentsCreate' == this.$route.name ||
            'FromChildrenGroupFromChildrenParentsCreate' == this.$route.name ||
            'FromChildrenGroupFromChildrenEditParent' == this.$route.name ||
            'EditParent' == this.$route.name ||
            'FromKindergartenFromChildrenEditParent' == this.$route.name ||
            'EditParentId' == this.$route.name ||
            'FromChildrenEditParent' == this.$route.name ||
            'FromObjectUsersCreate' == this.$route.name ||
            'FromObjectUsersCreateObjects' == this.$route.name ||
            'FromCompanyFromObjectFromUserGroupUsersCreateObjects' ==
              this.$route.name ||
            'FromKindergartenFromUserGroupUsersCreate' == this.$route.name ||
            'FromDealerFromObjectFromUserGroupUsersCreateObjects' ==
              this.$route.name ||
            'FromCompanyFromObjectUsersCreateObjects' == this.$route.name ||
            'FromDealerFromCompanyFromObjectUsersCreateObjects' ==
              this.$route.name ||
            'FromDealerFromKindergartenFromChildrenParentsCreate' ==
              this.$route.name ||
            'FromDealerFromKindergartenFromChildrenEditParent' ==
              this.$route.name ||
            'FromDealerFromKindergartenFromChildrenGroupFromChildrenParentsCreate' ==
              this.$route.name ||
            'FromDealerFromKindergartenFromChildrenGroupFromChildrenEditParent' ==
              this.$route.name ||
            'FromServesOrganizationFromObjectUsersCreateObjects' ==
              this.$route.name ||
            'usersGeneralEditId' == this.$route.name
        "
        class="title__avatar title__avatar-usersPage"
      ></div>
      <div
        v-else-if="
          'FromObjectCreateUserGroup' == this.$route.name ||
            'FromKindergartenCreateUserGroup' == this.$route.name ||
            'UserGroupsEdit' == this.$route.name ||
            'FromCompanyFromObjectCreateUserGroup' == this.$route.name ||
            'UserGroupsEditId' == this.$route.name ||
            'FromDealerFromCompanyFromObjectCreateUserGroup' ==
              this.$route.name ||
            'FromDealerFromObjectCreateUserGroup' == this.$route.name ||
            'FromServesOrganizationFromObjectCreateUserGroup' ==
              this.$route.name
        "
        class="title__avatar title__avatar-userGroupsPage"
      ></div>
      <div
        v-else-if="'CreatePromocodes' == this.$route.name"
        class="title__avatar title__avatar-promocodesPage"
      ></div>
      <div
        v-else-if="'Notification' == this.$route.name"
        class="title__avatar title__avatar-notificationPage"
      ></div>
      <div
        v-else-if="'CreateServiceOrganization' == this.$route.name"
        class="title__avatar title__avatar-serviceOrganizationPage"
      ></div>
      <div
        v-else-if="
          'createAdminOppen' == this.$route.name ||
            'FromCompanyCreateAdmin' == this.$route.name ||
            'FromDealerCreateAdmin' == this.$route.name ||
            'createAdmin' == this.$route.name ||
            'FromServesOrganizationCreateAdmin' == this.$route.name ||
            'FromDealerFromCompanyCreateAdmin' == this.$route.name
        "
        class="title__avatar title__avatar-oppenAdminsPage"
      ></div>

      <div
        v-else-if="
          'CreateServiceTaskPage' == this.$route.name ||
            'EditServiceTaskInfo' == this.$route.name ||
            'FromServesOrganizationCreateServiceOrganizationTask' ==
              this.$route.name ||
            'EditServiceTaskInfoId' == this.$route.name
        "
        class="title__avatar title__avatar-ordersPage"
      ></div>
      <div
        v-else-if="
          'ServiceGroupCreate' == this.$route.name ||
            'EditServiceGroup' == this.$route.name ||
            'FromServesOrganizationServiceGroupCreate' == this.$route.name ||
            'EditServiceGroupId' == this.$route.name
        "
        class="title__avatar title__avatar-objectsGroupPage"
      ></div>
      <div
        v-else-if="
          ('ProfileEdit' == this.$route.name &&
            this.$store.getters.getCurrentRole == 70) ||
            ('ProfileEdit' == this.$route.name &&
              this.$store.getters.getCurrentRole == 60) ||
            ('ProfileEdit' == this.$route.name &&
              this.$store.getters.getCurrentRole == 50) ||
            ('ProfileEdit' == this.$route.name &&
              this.$store.getters.getCurrentRole == 30) ||
            ('ProfileEdit' == this.$route.name &&
              this.$store.getters.getCurrentRole == 31)
        "
        class="title__avatar title__avatar-admin"
      ></div>
      <div
        v-else-if="
          'Split' == this.$route.name || 'DealerInfo' == this.$route.name
        "
        class="title__avatar title__avatar-terminal"
      ></div>
      <div
        v-else-if="'ProfileEdit' == this.$route.name"
        class="title__avatar title__avatar-profilePage"
      ></div>
      <div class="" style="color: red ; margin-right: 20px" v-else>
        {{ this.$route.name }}
      </div>

      <slot name="title__text" class="title__text">Компания</slot>
      <slot name="title-flex-right">
        <p class="title__text-info flex-right">
          Просмотр аналитики рабочего времени
        </p>
        <div class="switcher title__switcher">
          <input type="checkbox" class="checkbox_none" id="switcher" />
          <label class="switcher__inner" for="switcher">
            <div class="switcher__point"></div>
          </label>
          <p class="switcher__text">Выключено</p>
        </div>
      </slot>
    </div>
    <ValidationForm v-slot:default="{ handleSubmit }">
      <slot name="default" v-bind:handleSubmit="handleSubmit" />
      <div v-if="$scopedSlots.btns" class="btns card">
        <slot name="btns" v-bind:handleSubmit="handleSubmit"> </slot>
      </div>
    </ValidationForm>
  </div>
</template>

<script>
import ValidationForm from "@/components/ValidationField/ValidationForm";
export default {
  name: "CreateFormLayout",
  components: { ValidationForm }
};
</script>

<style scoped></style>
